import React from 'react';
import ServiceRequestForm from './ServiceRequestForm';

const LiveNeurocover = () => (
  <ServiceRequestForm
    serviceType="live_neurocover"
    apiEndpoint="/api/submit_service_request"
    title="Заявка на живой нейрокавер"
    description={[
      "'Ай, гитарист! (Андрей)' сам споет голосами легендартных певцов. Авторское исполнение для поклонников Андрея.",
      "❗ ВНИМАНИЕ",
      "Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram"
    ]}
    placeholder="Опишите, что хотели бы видеть в нейрокавере..."
  />
);

export default LiveNeurocover;