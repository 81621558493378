import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SearchVoice from './SearchVoice';
import NewVoice from './uslugi/NewVoice';
import Payment from './Payment';
import ProfilePage from './ProfilePage';
import Neurocover from './uslugi/Neurocover';
import LiveNeurocover from './uslugi/LiveNeurocover';
import ModelTraining from './uslugi/ModelTraining';
import SimpleGeneration from './generation/SimpleGeneration';
import CustomGeneration from './generation/CustomGeneration';
import AudioinputGeneration from './generation/AudioinputGeneration';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate replace to="/app/search_voice" />} />
        <Route path="/app/search_voice" element={<SearchVoice />} />
        <Route path="/app/payment" element={<Payment />} />
        <Route path="/app/profiles" element={<ProfilePage />} />
        <Route path="/app/uslugi/new_voice" element={<NewVoice />} />
        <Route path="/app/uslugi/neurocover" element={<Neurocover />} />
        <Route path="/app/uslugi/live_neurocover" element={<LiveNeurocover />} />
        <Route path="/app/uslugi/model_training" element={<ModelTraining />} />
        <Route path="/app/simple_generation" element={<SimpleGeneration />} />
        <Route path="/app/custom_generation" element={<CustomGeneration />} />
        <Route path="/app/audioinput_generation" element={<AudioinputGeneration />} />
      </Routes>
    </div>
  );
}

export default App;