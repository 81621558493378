import React from 'react';
import ServiceRequestForm from './ServiceRequestForm';

const NewVoice = () => (
  <ServiceRequestForm
    serviceType="new_voice"
    apiEndpoint="/api/new_voice"
    title="Заявка на добавление голоса"
    description={[
      "Мы добавляем модели голосов, которые есть в общем доступе. Если у нас не получится их найти - голос добавлен не будет.",
      "Добавляем только известных персонажей. Лично Ваш голос (ноунейм) никто добавлять не будет.",
	  "Если Вы хотите обучить голосовую модель на своем голосе - посмотрите раздел УСЛУГИ в боте"
    ]}
    placeholder="Напиши, голос какого исполнителя\персонажа Вы хотели бы Видеть в боте?"
  />
);

export default NewVoice;