import React from 'react';
import ServiceRequestForm from './ServiceRequestForm';

const Neurocover = () => (
  <ServiceRequestForm
    serviceType="neurocover"
    apiEndpoint="/api/submit_service_request"
    title="Заявка на нейрокавер"
    description={[
      "Запишем трек с помощью нейросетей, обработаем и изменим на выбранный Вами голос. Оставьте заявку и с Вами свяжутся в течении 15мин!",
      "❗ ВНИМАНИЕ",
      "Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram"
    ]}
    placeholder="Опишите, что хотели бы видеть в нейрокавере..."
  />
);

export default Neurocover;