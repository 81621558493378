import React, { useRef, useEffect, useState, useCallback } from 'react';

function TagPopup({ selectedTags, setSelectedTags, onClose }) {
  const [allTags, setAllTags] = useState([]);
  const popupRef = useRef(null);
  const overlayRef = useRef(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetch('/api/tags');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAllTags(data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();

    const popup = popupRef.current;
    const overlay = overlayRef.current;
    
    setTimeout(() => {
      popup.style.transform = 'translateY(0)';
      overlay.style.opacity = '1';
    }, 10);

    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = 'hidden'; 
    };
  }, []);

  const closePopup = useCallback(() => {
    const popup = popupRef.current;
    const overlay = overlayRef.current;
    
    popup.style.transform = 'translateY(100%)';
    overlay.style.opacity = '0';
    
    setTimeout(() => {
      onClose();
      document.body.style.overflow = 'hidden';
    }, 300);
  }, [onClose]);

  const toggleTag = useCallback((tag) => {
    setSelectedTags(prevTags => {
      if (prevTags.includes(tag)) {
        return prevTags.filter(t => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  }, [setSelectedTags]);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    popupRef.current.dataset.startY = touch.clientY;
    popupRef.current.dataset.startTransform = getComputedStyle(popupRef.current).transform;
  };

  const handleTouchMove = (e) => {
    const touch = e.touches[0];
    const startY = parseFloat(popupRef.current.dataset.startY);
    const deltaY = touch.clientY - startY;
    if (deltaY > 0) {
      popupRef.current.style.transform = `translateY(${deltaY}px)`;
    }
  };

  const handleTouchEnd = (e) => {
    const touch = e.changedTouches[0];
    const startY = parseFloat(popupRef.current.dataset.startY);
    const endY = touch.clientY;
    const deltaY = endY - startY;
    
    if (deltaY > 100) {
      closePopup();
    } else {
      popupRef.current.style.transform = popupRef.current.dataset.startTransform;
    }
  };

  return (
    <>
      <div 
        ref={overlayRef} 
        className="tag-popup-overlay" 
        onClick={closePopup}
        style={{ opacity: 0, transition: 'opacity 0.3s ease-out' }}
      ></div>
      <div 
        className="tag-popup" 
        ref={popupRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{ transform: 'translateY(100%)', transition: 'transform 0.3s ease-out' }}
      >
        <button className="close-button" onClick={closePopup}>×</button>
        <div className="tag-scroll">
          <div className="tag-grid">
            {allTags.map(tag => (
              <button
                key={tag}
                onClick={() => toggleTag(tag)}
                className={selectedTags.includes(tag) ? 'selected' : ''}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(TagPopup);