import React from 'react';
import ServiceRequestForm from './ServiceRequestForm';

const ModelTraining = () => (
  <ServiceRequestForm
    serviceType="model_training"
    apiEndpoint="/api/submit_service_request"
    title="Заявка на обучение модели"
    description={[
      "Обучим для Вас голосовую модель. Сами подготовим и обработаем датасет, проведем все корректировки и вышлем Вам готовые файлы. При желании - голос можно будет добавить в бота",
      "❗ ВНИМАНИЕ",
      "Чтобы исполнитель мог с Вами связаться - проверьте, установлен ли у Вас @логин в настройках Telegram"
    ]}
    placeholder="Опишите ваш запрос здесь..."
  />
);

export default ModelTraining;