import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const userId = urlParams.get('user_id');
        
        if (!userId) {
          throw new Error('User ID not provided');
        }

        const response = await fetch(`/api/get_profile?user_id=${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }

        const data = await response.json();
        setProfileData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [location]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!profileData) {
    return <div>No profile data available</div>;
  }

  const getTariffName = (tariff) => {
    return profileData.tariff_names[tariff] || tariff;
  };

  return (
    <div className="profile-page">
      <h1>Ваш профиль</h1>
      <p><strong>ID:</strong> {profileData.id}</p>
      <p><strong>Текущий тариф:</strong> {getTariffName(profileData.tariff)}</p>
      <p><strong>Доступно генераций сегодня:</strong> {profileData.credit_count}</p>
      <p><strong>Подписка действительна до:</strong> {profileData.subscription_end || '♾️'}</p>
      <p><em>Генерации обновляются по тарифу раз в день в 00:00</em></p>
    </div>
  );
};

export default ProfilePage;