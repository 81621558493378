import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WebApp, { getThemeParams } from '@twa-dev/sdk';
import Loader from './Loader';

function Payment() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [colorScheme, setColorScheme] = useState(WebApp.colorScheme);
  const location = useLocation();
  const navigate = useNavigate();
  const checkoutRef = useRef(null);
  const initializeAttemptedRef = useRef(false);

  useEffect(() => {
    const handleThemeChange = () => {
      setColorScheme(WebApp.colorScheme);
    };

    WebApp.onEvent('themeChanged', handleThemeChange);

    return () => {
      WebApp.offEvent('themeChanged', handleThemeChange);
    };
  }, []);

  const initializePayment = useCallback(async () => {
    if (initializeAttemptedRef.current) return;
    initializeAttemptedRef.current = true;

    try {
      const script = document.createElement('script');
      script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
      script.async = true;
      document.body.appendChild(script);

      await new Promise((resolve) => {
        script.onload = resolve;
      });

      const queryParams = new URLSearchParams(location.search);
      const amount = queryParams.get('amount');
      const userId = queryParams.get('user_id');
      const tariff = queryParams.get('tariff');
      const action = queryParams.get('action');

      if (!amount || !userId || !tariff || !action) {
        throw new Error('Отсутствуют необходимые параметры.');
      }

      const response = await fetch('/api/create_payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: parseFloat(amount), user_id: parseInt(userId), tariff, action }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.confirmation_token) {
        throw new Error('Не получен токен подтверждения от сервера');
      }

      await new Promise(resolve => setTimeout(resolve, 100));

      checkoutRef.current = new window.YooMoneyCheckoutWidget({
        confirmation_token: data.confirmation_token,
        return_url: window.location.origin + '/app/payment?status=success',
        customization: colorScheme === 'light' ? undefined : {
          colors: {
            background: '#1e1e1e',
            border: '#555555',
            control_primary: '#047bff',
            control_primary_content: '#ffffff',
            control_secondary: '#333333'
          }
        },
        error_callback: function(error) {
          console.error('YooKassa widget error:', error);
          setError('Произошла ошибка при оплате');
          WebApp.close();
        }
      });

      const paymentForm = document.getElementById('payment-form');
      if (!paymentForm) {
        throw new Error('Элемент payment-form не найден');
      }

      await checkoutRef.current.render('payment-form');
      setIsLoading(false);

      checkoutRef.current.on('success', async () => {
        try {
          const successResponse = await fetch('/api/payment_success', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              user_id: parseInt(userId),
              action: action,
              tariff: tariff,
              amount: parseFloat(amount),
              transaction_id: data.confirmation_token
            }),
          });

          if (!successResponse.ok) {
            throw new Error(`HTTP error! status: ${successResponse.status}`);
          }

          const successData = await successResponse.json();

          if (successData.status === 'success') {
            WebApp.sendData(JSON.stringify({
              type: 'payment_success',
              message: successData.message
            }));
            WebApp.close();
          } else {
            throw new Error(successData.message);
          }
        } catch (error) {
          console.error('Error processing payment:', error);
          setError('Произошла ошибка при обработке платежа');
          WebApp.close();
        }
      });

    } catch (error) {
      console.error('Error:', error);
      setError(`Ошибка: ${error.message}`);
      setIsLoading(false);
      WebApp.close();
    }
  }, [location.search, colorScheme]);

  useEffect(() => {
    initializePayment();

    return () => {
      if (checkoutRef.current) {
        checkoutRef.current.destroy();
      }
    };
  }, [initializePayment]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('status') === 'success') {
      WebApp.close();
    }
  }, [location.search]);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate('/app/payment');
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [navigate]);

  if (error) {
    return (
      <div className="error" style={{ textAlign: 'center', padding: '20px' }}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>
      {isLoading && <Loader />}
      <div id="payment-form" style={{ display: isLoading ? 'none' : 'block' }}></div>
    </>
  );
}

export default Payment;