import React, { useState, useEffect, useRef, useCallback } from 'react';
import WebApp from '@twa-dev/sdk';
import qusIcon from '../../assets/img/qus.svg';
import '../../App.css';

const GenerationForm = ({ 
  fields,
  onSubmit,
  children,
  renderHeader,
  extraData,
  onExtraDataChange,
  showResetButton = true,
  resetButtonLocation = 'outside',
  storagePrefix = ''
}) => {
  const [formData, setFormData] = useState({});
  const [showTooltips, setShowTooltips] = useState({});
  const [isExceeded, setIsExceeded] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [colorScheme, setColorScheme] = useState(WebApp.colorScheme);
  
  const tooltipRefs = useRef({});
  const helpIconRefs = useRef({});
  const textareaRefs = useRef({});
  const exceededTimeouts = useRef({});

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const savedData = {};
        fields.forEach(field => {
          const savedValue = localStorage.getItem(`${storagePrefix}${field.id}`);
          savedData[field.id] = savedValue ? savedValue.slice(0, field.maxLength) : '';
        });
        setFormData(savedData);
      } catch (error) {
        console.error('Error loading initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();

    const handleThemeChange = () => {
      setColorScheme(WebApp.colorScheme);
    };

    WebApp.onEvent('themeChanged', handleThemeChange);

    return () => {
      WebApp.offEvent('themeChanged', handleThemeChange);
    };
  }, [fields, storagePrefix]);

  const handleInputChange = (fieldId, maxLength) => (e) => {
    const newValue = e.target.value.slice(0, maxLength);
    setFormData(prev => {
      const updated = { ...prev, [fieldId]: newValue };
      localStorage.setItem(`${storagePrefix}${fieldId}`, newValue);
      return updated;
    });

    if (newValue.length === maxLength) {
      setIsExceeded(prev => ({ ...prev, [fieldId]: true }));
      
      if (exceededTimeouts.current[fieldId]) {
        clearTimeout(exceededTimeouts.current[fieldId]);
      }
      
      exceededTimeouts.current[fieldId] = setTimeout(() => {
        setIsExceeded(prev => ({ ...prev, [fieldId]: false }));
      }, 500);
    } else {
      setIsExceeded(prev => ({ ...prev, [fieldId]: false }));
    }
  };

  useEffect(() => {
    fields.forEach(field => {
      const textarea = textareaRefs.current[field.id];
      if (textarea) {
        const handlePaste = (e) => {
          e.preventDefault();
          const pastedText = e.clipboardData.getData('text');
          const currentText = textarea.value;
          const cursorPosition = textarea.selectionStart;
          const textBeforeCursor = currentText.slice(0, cursorPosition);
          const textAfterCursor = currentText.slice(cursorPosition);
          
          const newText = textBeforeCursor + pastedText + textAfterCursor;
          const truncatedText = newText.slice(0, field.maxLength);
          
          setFormData(prev => ({
            ...prev,
            [field.id]: truncatedText
          }));
          
          if (truncatedText.length === field.maxLength) {
            setIsExceeded(prev => ({ ...prev, [field.id]: true }));
            if (exceededTimeouts.current[field.id]) {
              clearTimeout(exceededTimeouts.current[field.id]);
            }
            exceededTimeouts.current[field.id] = setTimeout(() => {
              setIsExceeded(prev => ({ ...prev, [field.id]: false }));
            }, 500);
          }
        };
        
        textarea.addEventListener('paste', handlePaste);
        return () => textarea.removeEventListener('paste', handlePaste);
      }
    });
  }, [fields]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      fields.forEach(field => {
        if (showTooltips[field.id] && 
            tooltipRefs.current[field.id] && 
            !tooltipRefs.current[field.id].contains(event.target) &&
            !helpIconRefs.current[field.id].contains(event.target)) {
          setShowTooltips(prev => ({ ...prev, [field.id]: false }));
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltips, fields]);

  const handleCreateClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await onSubmit(formData, extraData);
      WebApp.close();
    } catch (error) {
      console.error('Error:', error);
      WebApp.showAlert('Произошла ошибка при отправке запроса');
    } finally {
      setIsLoading(false);
    }
  }, [formData, extraData, onSubmit]);

  const handleReset = () => {
    const resetData = {};
    fields.forEach(field => {
      resetData[field.id] = '';
      localStorage.removeItem(`${storagePrefix}${field.id}`);
    });
    setFormData(resetData);
    setIsExceeded({});
    if (onExtraDataChange) {
      onExtraDataChange(null);
    }
  };

  useEffect(() => {
    WebApp.MainButton
      .setText('Начать генерацию')
      .show();

    return () => {
      WebApp.MainButton.hide();
    };
  }, []);

  useEffect(() => {
    const isFormValid = fields.every(field => {
      const value = formData[field.id]?.trim() || '';
      return value !== '' && value.length <= field.maxLength;
    });
    if (isFormValid) {
      WebApp.MainButton.enable();
    } else {
      WebApp.MainButton.disable();
    }
  }, [formData, fields]);

  useEffect(() => {
    WebApp.MainButton.onClick(handleCreateClick);

    return () => {
      WebApp.MainButton.offClick(handleCreateClick);
    };
  }, [handleCreateClick]);

  useEffect(() => {
    const currentExceededTimeouts = exceededTimeouts.current;
    return () => {
      Object.values(currentExceededTimeouts).forEach(clearTimeout);
    };
  }, []);

  if (isLoading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }

  return (
    <div className="generation-form-container">
      <div className="generation">
        {fields.map((field, index) => (
          <div key={field.id}>
            <div className="form-header">
              {renderHeader ? renderHeader(field, index) : (
                <>
                  <div className="label-container">
                    <label htmlFor={field.id}>{field.label}</label>
                    <img
                      ref={el => helpIconRefs.current[field.id] = el}
                      src={qusIcon}
                      alt="Help"
                      className={`help-icon ${colorScheme === 'light' ? 'help-icon-invert' : ''}`}
                      onClick={() => setShowTooltips(prev => ({ ...prev, [field.id]: !prev[field.id] }))}
                    />
                    {showTooltips[field.id] && (
                      <div ref={el => tooltipRefs.current[field.id] = el} className="tooltip">
                        {field.tooltip}
                      </div>
                    )}
                  </div>
                  {resetButtonLocation === 'outside' && showResetButton && index === 0 && (
                    <button className="reset-button visible" onClick={handleReset}>
                      Сбросить всё?
                    </button>
                  )}
                </>
              )}
            </div>
            <div className={`form-container ${field.containerClass}`}>
              <textarea
                ref={el => textareaRefs.current[field.id] = el}
                id={field.id}
                value={formData[field.id] || ''}
                onChange={handleInputChange(field.id, field.maxLength)}
                placeholder={field.placeholder}
                rows={field.rows}
                maxLength={field.maxLength}
              />
              <div className="form-footer">
                <div className={`char-count ${isExceeded[field.id] ? 'exceeded' : ''}`}>
                  {(formData[field.id] || '').length} / {field.maxLength}
                </div>
                {resetButtonLocation === 'inside' && showResetButton && index === fields.length - 1 && (
                  <button className="reset-button visible" onClick={handleReset}>
                    Сбросить всё?
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};

export default GenerationForm;