import React from 'react';
import { useServiceRequest } from './useServiceRequest';

const ServiceRequestForm = ({ serviceType, apiEndpoint, title, description, placeholder }) => {
  const { description: inputDescription, isLoading, handleDescriptionChange } = useServiceRequest(serviceType, apiEndpoint);

  return (
    <div className="contact-us">
      <h1>{title}</h1>
      {description.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
      <div className="form-container">
        <textarea 
          value={inputDescription}
          onChange={handleDescriptionChange}
          placeholder={placeholder}
        />
        <div className="form-footer">
          <div className="char-count">
            {inputDescription.length} / 1000
          </div>
        </div>
      </div>
      {isLoading && <div className="loader-container"><div className="loader"></div></div>}
    </div>
  );
};

export default ServiceRequestForm;